import React from "react";

function IntegrationsHeader({
  searchQuery,
  setSearchQuery,
  iconUrl,
  heroImageUrl,
}) {
  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="flex w-full bg-[#00003C] py-18">
      <div className="container grid grid-rows-[1fr_2fr_1fr] lg:grid-cols-2 lg:grid-rows-2">
        <header className="mx-auto flex flex-col text-center text-white md:max-w-[585px]">
          <h1 className="text-left text-white">
            Real Estate Software Integration
          </h1>
          <p className="self-start text-left">
            Realsynch supports what your real estate team is already using for
            lead generation, transaction management, marketing, and more.
          </p>
        </header>
        <div className="col-span-1 col-start-1 row-start-3 flex items-center justify-center lg:row-start-2">
          <div className="relative mx-auto flex h-full w-full max-w-[585px] items-center">
            <input
              onChange={(e) => handleChange(e)}
              className="absolute w-full max-w-[585px] rounded-4xl border-[1px] border-[#673DEC] py-2 pl-8 text-[#525F7F] lg:w-[330px]"
              placeholder={searchQuery.length == 0 ? "SEARCH" : ""}
            />
            <img src={iconUrl} className="absolute py-2 pl-2" />
          </div>
        </div>
        <img
          src={heroImageUrl}
          className="row-start-2 justify-self-center lg:col-start-2 lg:row-span-2 lg:row-start-1"
        />
      </div>
    </div>
  );
}

export default IntegrationsHeader;
